@import "Fonts.css";

a {
  text-decoration: none;
  color: black;
}
a:visited {
  text-decoration: none;
  color: black;
}
a:link {
  text-decoration: none;
  color: black;
}
a:active {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: black;
}

.App {
  height: 9500px;
}

.Default {
  width: 100%;
  height: 100vh;
  /* border: 1px solid #000; */
  text-align: center;
  vertical-align: middle;
}

.DefaultText {
  font-family: "GmarketSansBold", sans-serif;
  letter-spacing: -1px;
  font-size: 30px;
  padding-top: 0px;
  margin-left: 50px;
}

.WelcomeDefault {
  width: 100%;
  height: 100vh;
  /* border: 1px solid #000; */
  text-align: center;
  vertical-align: middle;
  background-image: url(../images/bg.png);
  background-position: initial;
  background-size: cover;
  filter: invert(7%);
}

.welcomeDefaultDesc {
  text-align: center;
  font-family: "GmarketSansMedium";
  font-weight: 400;
  letter-spacing: -1px;
  font-size: 15px;
}

.ResumeDefault {
  text-align: center;
}

.IDDefault {
  width: 100%;
  height: auto;
  text-align: center;
  vertical-align: middle;
}

.IDDDefault {
  width: 100%;
  height: 110vh;
  padding-top: 10vh;
  text-align: center;
  vertical-align: middle;
}

.IDDDefaultText {
  text-align: center;
  font-family: "GmarketSansBold", sans-serif;
  font-weight: 900;
  letter-spacing: -1px;
  font-size: 30px;
  padding-top: 127px;
}

.IDDDefaultDesc {
  text-align: center;
  font-family: "GmarketSansMedium", sans-serif;
  font-weight: 400;
  letter-spacing: -1.7px;
  font-size: 20px;
}

.KDefault {
  width: 100%;
  height: 100vh;
  /* border: 1px solid #000; */
  text-align: center;
  vertical-align: middle;
}

.KDefault div {
  display: inline-block;
}

.KDefaultText {
  font-family: "GmarketSansBold", sans-serif;
  letter-spacing: -1px;
  font-size: 30px;
  padding-top: 100px;
}

.keywordbox {
  width: 140px;
  height: 50px;
  border: 1px solid #eb1d47;
  border-radius: 50px;
  text-align: center;
  margin: 5px 5px;
  background-color: rgba(235, 29, 71, 0.05);
}

.keywordbox p {
  height: 50px;
  text-align: center;
  margin-top: 15.5px;
  color: #eb1d47;
  font-family: "GmarketSansMedium";
  font-size: 13.5px;
}

.subkeywordbox {
  width: 140px;
  height: 50px;
  border: 1px solid rgb(90, 90, 90);
  border-radius: 50px;
  text-align: center;
  margin: 5px 5px;
  background-color: white;
}

.subkeywordbox p {
  height: 50px;
  text-align: center;
  margin-top: 15.5px;
  color: rgb(90, 90, 90);
  font-family: "GmarketSansMedium";
  font-size: 13.5px;
}

.Kdescdiv {
  margin-top: 50px;
}

.Kdesc {
  text-align: center;
  font-family: "GmarketSansMedium";
  font-weight: 400;
  letter-spacing: -1px;
  font-size: 15px;
  margin-top: 30px;
}

p {
  margin-top: -10px;
}

video {
  width: 100%;
  z-index: -1;
}

.ContactDefault {
  width: 100%;
  height: 500px;
  text-align: center;
  vertical-align: middle;
  background-color: rgb(245, 245, 245);
  padding-bottom: 100px;
}

.ContactText {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  letter-spacing: -1px;
  font-size: 30px;
  padding-top: 30px;
  color: black;
}

.welcome {
  align-items: center;
  list-style: none;
  display: inline-flex;
  font-family: "GmarketSansBold";
  font-weight: 900;
  text-align: center;
  font-size: 60px;
  color: black;
  margin-top: 350px;
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.test li {
  margin-left: 50px;
}

.Flag_KOR {
  align-content: center;
  vertical-align: middle;
}

.contacticon {
  justify-content: space-evenly;
  display: inline-flex;
  text-align: center;
  width: 70%;
}

.contacticon li {
  display: inline-block;
}

.contacticon li p {
  color: balck;
  font-family: "Montserrat";
  font-weight: 200;
  font-size: 13px;
  margin-top: 5px;
}

.B2T {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
}

.B2T img {
  filter: invert(80%);
  margin-top: 30px;
  transition: all 0.2s ease-in-out;
}

.B2T:hover img {
  transform: translateY(-15px);
}

.Footer p {
  font-family: "Montserrat", sans-serif;
  margin-top: 50px;
}

.Footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100px;
  padding-top: 1px;
  padding-bottom: 10px;
  margin-top: -10px;
  z-index: 1;
  background-color: white;
  filter: drop-shadow(0 2mm 10mm rgb(223, 223, 223));
}

.Footer_logo {
  filter: contrast(0%);
  margin-top: 35px;
  display: block;
  margin-left: 30px;
}

.footerinfo {
  text-align: left;
  margin-top: -45px;
  margin-left: 120px;
  font-size: 13px;
  font-family: "Montserrat";
  font-weight: 200;
}

.langsel {
  text-align: right;
  margin-right: 20px;
  margin-top: -30px;
  font-family: "Montserrat";
}

@media (max-width: 768px) {
  .footerinfo {
    font-size: 7px;
    margin-left: 100px;
    margin-top: -32px;
  }

  .Footer_logo {
    width: 50px;
  }

  .langsel {
    margin-top: -25px;
    margin-right: 20px;
  }

  .IDDDefault {
    padding-bottom: 150px;
  }

  .IDDDefaultDesc {
    font-size: 17px;
    text-align: left;
  }

  .IDDDefaultText {
    margin-left: 20px;
    text-align: left;
  }

  .IDDDefaultDesc {
    font-size: 17px;
    margin-left: 20px;
    padding-right: 30px;
  }

  .KDefault {
    height: auto;
  }

  .KDefaultText {
    margin-left: 0px;
  }

  .Kdesc {
    padding-right: 30px;
    padding-left: 30px;
    text-align: left;
  }

  .contacticon {
    justify-content: space-evenly;
    display: inline;
    width: 100%;
  }

  .contacticon img {
    width: 50px;
  }

  .contacticon li {
    margin-left: 3px;
    margin-right: 3px;
  }

  .contacticon li p {
    font-size: 7px;
  }

  .ResumeDefault img {
    width: 90%;
  }
}
