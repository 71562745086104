.ArtContent {
  width: 100%;
  height: 4300px;
  /* border: 1px solid #000; */
  text-align: center;
  vertical-align: middle;
}

.DefaultText {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  letter-spacing: -1px;
  font-size: 30px;
  margin-top: 50px;
}

.ArtBox {
  width: 273px;
  height: 490px;
  border: 1px solid rgb(199, 199, 199);
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-top: -70px;
}

.atitle {
  text-align: left;
  margin-left: 10px;
  font-family: "GmarketSansLight";
  font-weight: bolder;
  font-size: 14px;
  color: rgb(99, 99, 99);
}

.adescp {
  text-align: left;
  margin-left: 10px;
  margin-top: -3px;
  font-family: "GmarketSansLight";
  font-size: 12px;
  color: black;
  padding-right: 5px;
}

.ArtWide {
  width: 450px;
  height: 490px;
  border: 1px solid rgb(199, 199, 199);
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-top: -70px;
}

.ArtVideo {
  width: 450px;
  height: 430px;
  border: 1px solid rgb(199, 199, 199);
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.ArtWide img {
  margin-bottom: 15px;
}
.ArtVideo video {
  margin-bottom: 15px;
}

.Youtube_api {
  width: 450px;
  height: 225px;
  margin-bottom: 15px;
}

.ArtContent ul {
  list-style: none;
  display: inline-flex;
  padding-bottom: 100px;
}

.ArtContent ul li {
  margin-right: 20px;
}

.ATitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  letter-spacing: -1px;
  font-size: 30px;
  margin-top: -10px;
  margin-bottom: 100px;
}

.Artinfo {
  margin-top: 20px;
}

.ModalClick {
  cursor: pointer;
  width: 450px;
  height: 253px;
  overflow: hidden;
}

.ModalClick img {
  width: 450px;
  height: 253px;
  transition: 0.3s all;
}

.ModalClick:hover img {
  transform: scale(1.15);
  filter: brightness(130%);
  overflow: hidden;
}

.ModalClickS {
  cursor: pointer;
  width: 273px;
  height: 273px;
  overflow: hidden;
  text-align: center;
  background-color: #151515;
}

.ModalClickS img {
  height: 100%;
  transition: 0.3s all;
}

.ModalClickS:hover img {
  transform: scale(1.15);
  filter: brightness(110%);
  overflow: hidden;
}

.ModalClick img:hover .ModalBox {
  display: block;
  z-index: 21;
}

.ModalBox {
  width: 70%;
  justify-content: center;
  align-items: center;
  border: none;
}

.ModalBoxS {
  width: 33%;
  justify-content: center;
  align-items: center;
  border: none;
}

.ModalBox img {
  display: block;
  margin: 0;
}

.ModalBoxS img {
  display: block;
  margin: 0;
}

@media (max-width: 768px) {
  .ArtContent ul {
    display: inline-table;
  }

  .ArtContent ul li {
    margin-bottom: 150px;
  }

  .ArtBox {
    width: 250px;
    height: 250px;
  }

  .ArtVideo {
    width: 250px;
    height: 250px;
  }

  .ArtWide img {
    width: 273px;
    height: auto;
  }

  .ArtVideo video {
    width: 273px;
    height: auto;
  }

  .ArtWide {
    margin-top: -130px;
    margin-bottom: 150px;
    align-content: center;
    width: 250px;
    height: auto;
  }

  .ArtContent {
    height: auto;
  }

  .ModalClick {
    width: 273px;
  }

  .ModalBox {
    width: 90%;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .ModalBoxS {
    width: 90%;
    justify-content: center;
    align-items: center;
    border: none;
  }
}
